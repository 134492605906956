<template>
    <a-modal
        :visible="visible"
        :title="`Unggah Target ${validateRoles([ROLE_DISTRIBUTOR]) ? 'Distributor' : 'SPC'}`"
        @cancel="handleModalCancel"
        width="70%"
        :destroy-on-close="true">

        <template #footer>
            <AButton
                @click="handleModalCancel">Batal</AButton>
            <AButton
                key="submit"
                type="primary"
                :loading="state.loading"
                @click="handleSubmitOk">Submit</AButton>
        </template>

        <AForm
            ref="formRef"
            class="myform"
            :model="state.form"
            :rules="state.rules"
            @finish="btnUploadFile()"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- upload -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Unggah Dokumen"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        has-feedback>
                        <a-input
                            type="file"
                            required
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            @change="onFileChange"
                        ></a-input>
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- pilih target user -->
            <ARow
                v-if="hasRoles([ROLE_ADMIN_BK])"
                class="form-row">
                <ACol :xl="12" :md="24">
                    <AFormItem
                        label="Target User"
                        label-align="left"
                        :label-col="{ md: { span: 8 } }"
                        :wrapper-col="{ md: { span: 24 - 8 } }"
                        required
                        name="user_target_id"
                        has-feedback>
                        <!-- 1: distributor, 747552: spc -->
                        <FilterUsers
                            v-model:value="state.form.user_target_id"
                            :role_id="validateRoles([ROLE_DISTRIBUTOR]) ? 1 : 747552"
                            :nopeg_status="0"
                            :mode="null"
                            style="width:100%;"
                            placeholder="Pilih User Target"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <a-row class="form-row mb-4">
                <ACol :sm="4"/>
                <a-col :sm="20">
                    <ASpace>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="state.form.loading">Upload</a-button>
                        <DownloadTemplate
                            :url="state.endpoint_template"
                            :params="{
                                role_id: validateRoles([ROLE_DISTRIBUTOR]) ? 1 : 747552,
                                user_target_id: state.form.user_target_id,
                            }"
                            :namefile="`Master-Target-Penjualan-${validateRoles([ROLE_DISTRIBUTOR]) ? 'Distributor': 'SPC'}`"
                            :errors="errorMessage"/>
                    </ASpace>
                </a-col>
            </a-row>
        </AForm>

        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <br>

        <MdTable
            :columns="state.columns"
            :data-source="state.data"
            row-key="code"
            :row-selection="rowSelection"
            size="small"
            :loading="state.loading">
            <template #tags="{ record }">
                <a-tag :color="record.errors.length == 0 ? 'green' : 'volcano'">
                <template #icon>
                    <template v-if="record.errors.length == 0"> <CheckCircleOutlined /> Ya </template>
                    <template v-else>
                        <APopover trigger="click">
                            <template #content>
                                <AList size="small" :data-source="record.errors">
                                    <template #renderItem="{ item }">
                                        <AListItem>{{ item }}</AListItem>
                                    </template>
                                </AList>
                            </template>
                            <CloseCircleOutlined /> Tidak
                        </APopover>
                    </template>
                </template>
                </a-tag>
            </template>
        </MdTable>

         <AModal
            v-model:visible="state.result.isShow"
            title="Result"
            @cancel="handleModalCancel"
            @ok="handleModalCancel">
            <template #footer>
                <AButton key="back" type="primary" @click="handleShowCancel">OK</AButton>
            </template>
            <AAlert type="success" :message="`Sukses: ${state.result.valid}`"></AAlert>
            <AAlert type="error">
                <template #message>
                {{ `Gagal: ${state.result.failed}` }}
                    <a-tree :tree-data="state.result.data" default-expand-all/>
                </template>
            </AAlert>
        </AModal>

    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
    computed,
} from 'vue'
import apiClient from '@/services/axios'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'
import { Modal, message } from 'ant-design-vue'
import DownloadTemplate from '@/components/Molecules/DownloadTemplate'
import FilterUsers from '@/components/filter/FilterUsers'
import _ from 'lodash'
import {
    hasRoles,
    ROLE_ADMIN_BK,
    ROLE_DISTRIBUTOR,
    ROLE_ASM,
    ROLE_SSM,
    ROLE_SPC,
    ROLE_SPC_GROUP,
    ROLE_SPC_TARGET,
} from '@/helpers'

export default defineComponent({
    components: {
        CheckCircleOutlined,
        CloseCircleOutlined,
        DownloadTemplate,
        FilterUsers,
    },
    props: {
        visible: [Boolean],
        params: {
            type: Object,
            default: () => ({}),
        },
        roles: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const errorMessage = ref()

        const formRef = ref();

        const state = reactive({
            columns: [
                {
                    title: 'Valid?',
                    dataIndex: 'valid',
                    slots: {
                        customRender: 'tags',
                    },
                },
            ],
            columnDistributor: [
                {
                    title: 'Periode',
                    dataIndex: 'periode',
                },
                {
                    title: 'Toko',
                    dataIndex: 'nama_customer',
                },
                {
                    title: 'ID Customer',
                    dataIndex: 'kode_customer',
                },
                {
                    title: `Demand SIG`,
                    dataIndex: 'periode_serialize',
                    children: [
                        {
                            title: 'SG',
                            dataIndex: 'field',
                        },
                        {
                            title: 'SP',
                            dataIndex: 'field',
                        },
                        {
                            title: 'ST',
                            dataIndex: 'field',
                        },
                        {
                            title: 'Total',
                            dataIndex: 'field',
                        },
                    ],
                },
                {
                    title: 'Demand Toko Total (Semua Brand)',
                    dataIndex: 'demand_all_brand',
                },
                {
                    title: 'MS Target',
                    dataIndex: 'market_sale_target',
                },
                {
                    title: 'MS SIG per Toko',
                    dataIndex: 'market_sale_actual',
                },
                {
                    title: 'GAP MS Toko vs MS Target',
                    dataIndex: 'market_sale_gap',
                },
            ],
            columnSPC: [
                {
                    title: 'Periode',
                    dataIndex: 'periode',
                },
                {
                    title: 'Distributor',
                    dataIndex: 'nama_distributor',
                },
                {
                    title: 'Kabupaten/Kota',
                    dataIndex: 'kabupaten',
                },
                {
                    title: `Demand SIG`,
                    dataIndex: 'periode_serialize',
                    children: [
                        {
                            title: 'SG',
                            dataIndex: 'field',
                        },
                        {
                            title: 'SP',
                            dataIndex: 'field',
                        },
                        {
                            title: 'ST',
                            dataIndex: 'field',
                        },
                        {
                            title: 'Total',
                            dataIndex: 'field',
                        },
                    ],
                },
            ],
            columnSplice: 4, // default distributor
            rules: {
                user_target_id: [
                    {
                        required: true,
                        message: "User target tidak boleh kosong!",
                    },
                ],
            },
            endpoint: '/api/snop/target-penjualan-distributor/upload',
            endpoint_template: '/api/snop/target-penjualan-distributor/unduh-template',
            data: [],
            loading: false,
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            selectedRowKeys: [],
            selectedRows: [],
            result: {
                isShow: false,
                data: [],
                valid: 0,
                failed: 0,
            },
            form: {
                loading: false,
                files: null,
                file: null,
                user_target_id: null,
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
        }

        const onFileChange = (e) => {
            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return
            state.form.file = files[0]
        }

        const rowSelection = computed(() => {
            return {
                selectedRowKeys: state.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    state.selectedRowKeys = selectedRowKeys
                    state.selectedRows = selectedRows
                },
                getCheckboxProps: record => ({
                    disabled: record.errors.length > 0,
                }),
            }
        })
        
        const transformData = (data) => {
            return _.map(data, item => {
                const brandData = _.reduce(item.brands, (result, brand) => {
                    result[`demand_value_${brand.brand_id}`] = brand.demand_value
                    return result
                }, {});

                return {
                    ...item,
                    ...brandData,
                };
            });
        }

        const btnUploadFile = async () => {
            // validation form
            await formRef.value.validate()
                .catch(() => { });
            
            state.form.loading = true

            const form_data = new FormData()

            form_data.append('file', state.form.file)
            form_data.append('user_target_id', state.form.user_target_id)

            apiClient.post(state.endpoint, form_data)
                .then(({ data }) => {
                    const columnChildren = _(data)
                        .flatMap(item => item.brands.map(brand => ({
                            title: _.join(_.map(brand.brand_name.split(' '), word => word[0]), ''),
                            dataIndex: `demand_value_${brand.brand_id}`,
                        })))
                        .groupBy('dataIndex')
                        .map(brands => _.mergeWith({}, ...brands, (objValue, srcValue) => _.isUndefined(objValue) ? srcValue : objValue))
                        .value()

                    state.columns.splice(state.columnSplice, 1, {
                        title: `Demand SIG`,
                        dataIndex: 'periode_serialize',
                        children: [
                            ...columnChildren,
                            {
                                title: 'Total',
                                dataIndex: 'total_demand',
                            },
                        ],
                    });

                    state.data = transformData(data)
                })
                .catch(async error => {
                    errorMessage.value = null

                    if (error.response && (error.response.status === 500 || error.response.status === 422)) {
                        const { status, statusText } = error.response
                        const message = error.response.data.message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }

                    if (error.response && error.response.status !== 500) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }

                    message.error('Gagal mengungah!')
                })
                .finally(() => {
                    state.form.loading = false
                })
        }

        const handleSubmitOk = async () => {
            state.result.data = []
            state.result.valid = 0
            state.result.failed = 0
            errorMessage.value = null
            state.loading = true

            let payloads = []
            state.selectedRows.forEach(item => {
                payloads.push({
                    ...item,
                    valid: item.errors.length == 0,
                })
            })

            apiClient
                .post(state.endpoint, { simpan: payloads })
                .then(({ data }) => {
                    if (Array.isArray(data) && data.length === 0) {
                        message.warning('Tidak ada data!, pastikan sudah memilih datanya')
                        return
                    }

                    state.result.isShow = true

                    data.forEach((item, idx) => {
                        if (item.success) {
                            state.result.valid++
                        } else {
                            state.result.failed++
                            const branch = {}
                            state.result.data.push(branch)
                            branch.title = 'Baris ' + (idx + 1)
                            branch.key = idx
                            branch.children = []
                            if (item.model.errors) {
                                item.model.errors.forEach((v, i) => {
                                    branch.children.push({
                                        title: `Kolom ${v.field} : ${v.message}`,
                                        key: `${idx}-${i}`,
                                    })
                                })
                            }
                        }
                    })
                    
                    emit('success', data)
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = error.response.data.message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.loading = false
                    state.selectedRowKeys = []
                    state.selectedRows = []
                })
        }

        const handleShowCancel = () => {
            state.result.isShow = false
            handleModalCancel()
        }

        const validateRoles = (roles = []) => {
            return props.roles.every(role => roles.includes(role));
        }

        onMounted(() => {
            if (validateRoles([ROLE_DISTRIBUTOR]) && hasRoles([ROLE_ADMIN_BK, ROLE_DISTRIBUTOR])) {
                state.columns = _.concat(state.columns, state.columnDistributor)
            }

            if (validateRoles([ROLE_SPC]) && hasRoles([ROLE_ADMIN_BK, ROLE_SPC,ROLE_SPC_GROUP, ROLE_SPC_TARGET])) {
                state.columnSplice = 4
                state.columns = _.concat(state.columns, state.columnSPC)
            }
        })

        return {
            errorMessage,
            state,
            formRef,
            btnUploadFile,
            handleSubmitOk,
            handleModalCancel,
            onFileChange,
            rowSelection,
            handleShowCancel,
            validateRoles,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
            ROLE_DISTRIBUTOR,
            ROLE_ASM,
            ROLE_SSM,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
